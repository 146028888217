import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { CustomerCenter, UserDetail } from '../api-types';
import { environment, isDev } from '../environment';
import { AnalyticsEvent, AnalyticsEventEnum } from './metrics-events';
import { processEvent } from './metrics-processor';

/** ***************************************************************
 * TRACKING UTILITY FUNCTIONS
 **************************************************************** */

/**
 * Init mixpanel library
 */
if (!isDev() && environment.mixpanelToken !== '') {
  mixpanel.init(environment.mixpanelToken, {
    debug: isDev(),
    ignore_dnt: true,
    batch_requests: true,
  });
}

/**
 * Initialize user with the mixpanel library
 * @param userInfo - UserInfo
 */

/**
 * Profile Properties
 */
interface AnalyticsUserData {
  Username: string;
  $name?: string;
  'Center ID'?: string;
  'Customer ID'?: string;
  'Teleradiology Company ID'?: string;
  Groups: string[];
}

function setUser(userInfo: UserDetail, selectedCenter?: CustomerCenter) {
  if (isDev() || environment.mixpanelToken === '') return;

  mixpanel.identify(userInfo.id);

  const userData: AnalyticsUserData = {
    Username: userInfo.username,
    $name: `${userInfo.first_name} ${userInfo.last_name}`.trim(),
    'Center ID': selectedCenter?.id,
    'Customer ID': userInfo.customer?.user_id,
    'Teleradiology Company ID': userInfo.groups.some((group) => group.name === 'RadiologyCompany')
      ? userInfo.id
      : undefined,
    Groups: userInfo.groups.map((group) => group.name),
  };

  mixpanel.people.set(userData);
}

/**
 *
 * @param event - Event name
 * @param properties - Event properties
 */
function trackMixpanelEvent(event: string, properties?: any) {
  if (isDev()) console.debug('Track event', event, properties);
  else mixpanel.track(event, properties);
}

/**
 * Track event with typed data
 * @param event - Analytics Event Data
 */
function trackEvent(event: AnalyticsEvent) {
  try {
    const mixpanelPayload = processEvent(event);

    const payload = (mixpanelPayload as any)?.payload;
    if (!payload) {
      trackMixpanelEvent(event.type);
    } else if (payload instanceof Array) {
      payload.forEach((load: any) => {
        trackMixpanelEvent(event.type, load);
      });
    } else {
      trackMixpanelEvent(event.type, payload);
    }
  } catch (err) {
    console.log('Tack event failed', err);
  }
}

/**
 * Reset mixpanel settings (Logout)
 */
function resetEvent() {
  try {
    mixpanel.reset();
  } catch {
    _.noop();
  }
}

export { trackEvent, setUser, resetEvent, AnalyticsEventEnum };
