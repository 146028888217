import wretch from 'wretch';
import { message } from 'antd';
import { constants, publicRoutes } from './constants';
import { environment } from './environment';

let authToken = '';

function redirectToLogin() {
  if(localStorage.getItem('AUTH_TOKEN')?.includes("refresh")){
    message.error("Session expired.");
  }
  if (window.location.pathname !== '/login') window.location.replace('/login');
  localStorage.clear();
}

const serverAPI = wretch()
  .url(constants.serverURL)
  .resolve((_) => _.unauthorized(() => {
    if(publicRoutes.includes(window.location.pathname) === false) {
      redirectToLogin();
    }
  }));

function setAPIAuthToken(accessToken: string) {
  authToken = `Bearer ${accessToken}`;
}

function clearAPIAuthToken() {
  authToken = '';
}

const getServerAPI = () => serverAPI.auth(authToken);

export { getServerAPI, setAPIAuthToken, clearAPIAuthToken };

console.log('gen ai base url -->', environment.genAiBaseUrl)

// GEN AI SERVER
const genAIserverAPI = wretch(environment.genAiBaseUrl)

export const getGenAIserverAPI = () => genAIserverAPI;