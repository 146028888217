import { useEffect } from 'react';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';
import { isDev } from '../../common/environment';

/* ******************************************
 * SERVICEE WORKER REGISTRATION
 ***************************************** */

/**
 * Utility to register service worker
 */
function registerServiceWorker() {
  if (!isDev() && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      navigator.serviceWorker.register(swUrl);
    });
  }
}

/* ******************************************
 * PWA INSTALLATION RELATED
 * For a indepth understanding of the process visit
 * https://web.dev/customize-install/
 ***************************************** */

/**
 * Variable to store the prompting event, as event to
 * prompt the user for installation from UI
 */
let deferredPrompt: Event | null = null;

/**
 * Recoil state to decide whether Add to home screen prompt
 * should be visible
 */
const add2HsPromptState = atom<boolean>({
  key: 'isA2HSPromptVisible',
  default: false,
});

/**
 * Hook to add event listener and store the event for a2hs button
 */
const useA2HSSetup = () => {
  const setIsPromptVisible = useSetRecoilState(add2HsPromptState);

  /**
   * Effect to register beforeinstallprompt and capture installPrompt event
   */
  useEffect(() => {
    const savePromptEvent = async (e: Event) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      setIsPromptVisible(true);
    };

    /**
     * Event Listener to customize the add to homescreen button
     */
    window.addEventListener('beforeinstallprompt', savePromptEvent);
    return () => {
      window.removeEventListener('beforeinstallprompt', savePromptEvent);
    };
  }, []);

  /**
   * Effect to listen for appinstalled event (add is already installed)
   * and hide the A2HS prompt
   */
  useEffect(() => {
    const deletePromptEvent = () => {
      // Hide the app-provided install promotion
      setIsPromptVisible(false);
      // Clear the deferredPrompt so it can be garbage collected
      deferredPrompt = null;
    };
    window.addEventListener('appinstalled', deletePromptEvent);

    return () => {
      window.removeEventListener('appinstalled', deletePromptEvent);
    };
  }, []);
};

/**
 * Hook to Customize the Add to HomeScreen Button
 * @returns isPromptVisible -> whether the prompt shpuld be visible
 * @returns onAccept, onClose -> callbacks for the prompt
 */
const useAddToHomeScreenPrompt = () => {
  const [isPromptVisible, setIsPromptVisible] = useRecoilState(add2HsPromptState);

  const showA2HSPrompt = async () => {
    if (deferredPrompt !== null) {
      (deferredPrompt as any).prompt();

      // Wait for the user to respond to the prompt
      const { outcome }: { outcome: string } = await (deferredPrompt as any).userChoice;
      if (outcome === 'accepted') deferredPrompt = null;
    }
    setIsPromptVisible(false);
  };

  return {
    isPromptVisible,
    showA2HSPrompt,
  };
};

export { registerServiceWorker, useAddToHomeScreenPrompt, useA2HSSetup };
