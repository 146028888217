import { useContext } from "react";
import { useQuery } from "react-query";
import { AppConfig } from "../../common/config/appSettingConfig";
import { getServerAPI } from "../../common/serverAPI";

import { AuthContext } from "../auth/AuthContext";


// billing rates of customer
async function getUserConfigForAPP(): Promise<AppConfig> {
  
  const data = await getServerAPI()
    .url(`v2/telereport/get_user_config/`)
    .get()
    .json<AppConfig>();

  return data;
}

const useUserConfig = () => {
  const { authInfo } = useContext(AuthContext);
  // console.log(authInfo)
  if(authInfo.authStatus === undefined && authInfo.authStatus === 2){
    return {} as AppConfig;
  }
    const data = useQuery(['user_config'], getUserConfigForAPP, {
      staleTime: 10,
    });
  
    return data.data;
  
};


export{
  getUserConfigForAPP,
  useUserConfig
}