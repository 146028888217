/* eslint-disable no-case-declarations */
import {
  BulkStudyReportTaskCreateResponse,
  BulkStudyReportTaskInput,
  PatientGenderMap,
  PatientGenderSting,
  StudyReportTask,
  StudyReportTaskInput,
} from '../api-types';
import { getDifferenceInSeconds } from '../date-utils';
import { ArrayElement } from '../typescript-utils';
import { AnalyticsEventEnum, AnalyticsEvent } from './metrics-events';

/** ***************************************************************
 * MIXPANEL EVENT PAYLOAD TYPE
 **************************************************************** */

export interface UserLoggedIn {
  type: AnalyticsEventEnum.USER__LOGGED_IN;
  payload: {
    'Login Type': 'Login' | 'Saved';
  };
}

export interface UserLoggedOut {
  type: AnalyticsEventEnum.USER__LOGGED_OUT;
}

export interface XrayScanUpload {
  type: AnalyticsEventEnum.XRAYSCAN__UPLOAD;
  payload: {
    'Upload Scan Source': 'JPG' | 'DICOM' | 'RECEIVER';
    'Upload Input Duration': number;
    'Scan ID': string;
    'Patient ID'?: string;
    'Patient Gender': PatientGenderSting;
    'Patient Age': number;
    'Patient History Length'?: number;
    'Referring Physician'?: string;
    'No Of Images': number;
    'Is Bulk Upload': boolean;
    'Scan Organs': string[];
    'Scan Priority': number;
    'Has Attachment': boolean;
    'Is Portable': boolean;
  }[];
}
export interface XrayScanAssignedRadiologist {
  type: AnalyticsEventEnum.XRAYSCAN__RADIOLOGIST_ASSIGNED;
  payload: {
    'Radiologist ID': number;
    'Scan ID': string;
    'Time To Assign': number;
  }[];
}

export interface XrayScanDelete {
  type: AnalyticsEventEnum.XRAYSCAN__DELETE;
  payload: {
    'Report ID': string;
    'Deleted Scan Type': 'Normal' | 'Draft';
    'Time To Delete': number;
  };
}

export interface XrayScanReportDownload {
  type: AnalyticsEventEnum.XRAYSCAN__REPORT_DOWNLOAD;
  payload: {
    'Scan ID': string;
  };
}

export interface XrayScanUpdate {
  type: AnalyticsEventEnum.XRAYSCAN__EDIT;
  payload: {
    'Scan ID': string;
    'Time To Edit': number;
  };
}

export interface XrayScanReportSave {
  type: AnalyticsEventEnum.XRAYSCAN__REPORT_SAVE;
  payload: {
    'Scan ID': string;
    'Time to Save': number;
  };
}

export interface XrayStudyReportSubmit {
  type: AnalyticsEventEnum.XRAYSCAN__REPORT_SUBMIT;
  payload: {
    'Scan ID': string;
    'Time To Submit': number;
  };
}

export interface CustomerSupportIssueCreated {
  type: AnalyticsEventEnum.CUSTOMER_SUPPORT_ISSUE_CREATED;
  payload: {
    'Scan ID': string;
    'Center Name': string;
    'Time To Report': number;
    'Scan Priority': number;
    'Issue Type': string;
  };
}

export interface ReportShare {
  type: AnalyticsEventEnum.REPORT_SHARE;
  payload: {
    'Scan ID': string;
    Phone: string;
    'Contact Type': string;
    Channels: string[];
  };
}

type MixpanelEventPayload =
  | UserLoggedIn
  | UserLoggedOut
  | XrayScanUpload
  | XrayScanAssignedRadiologist
  | XrayScanDelete
  | XrayScanReportDownload
  | XrayScanUpdate
  | XrayStudyReportSubmit
  | XrayScanReportSave
  | CustomerSupportIssueCreated
  | ReportShare;

/**
 * Event processor to convert the event detail passed from the App to properties
 * that should be sent to mixpanel.
 * The reason for keeping the AnalyticsEvent separate from the MixpanelEventPayload
 * is to not pollute application code with the logic and transformation required
 * to generate compute the mixpanel properties for a given event.
 * The app is responsible to send some values that are easily available in the
 * App (generic types / entities) and the processor should convert them to appropriate
 * event properties
 *
 * @param event - Analytics Event
 */
const processEvent = (event: AnalyticsEvent): MixpanelEventPayload | undefined => {
  switch (event.type) {
    case AnalyticsEventEnum.USER__LOGGED_IN:
      return {
        type: event.type,
        payload: {
          'Login Type': event.payload.type,
        },
      };
    case AnalyticsEventEnum.USER__LOGGED_OUT:
      return {
        type: event.type,
      };
    case AnalyticsEventEnum.XRAYSCAN__UPLOAD:
      const inputDuration = getDifferenceInSeconds(new Date(), event.payload.uploadStartTime);
      let payload: XrayScanUpload['payload'] = [];

      const seriesDescriptionProcessor = (desc: string) => {
        try {
          const obj = JSON.parse(desc);
          return obj.organ;
        } catch {
          return desc;
        }
      };

      if (event.payload.type === 'BULK') {
        const studyReportTask = event.payload.studyReportTask as BulkStudyReportTaskInput;
        const { patient } = studyReportTask;
        const response = event.payload.response as BulkStudyReportTaskCreateResponse;
        const isBulk = event.payload.type === 'BULK' && response.length > 1;
        payload = response.map<ArrayElement<typeof payload>>((task) => ({
          'Upload Scan Source': event.payload.source,
          'Upload Input Duration': inputDuration,
          'Scan ID': task.id,
          'Patient ID': patient.patient_id,
          'Patient Gender': PatientGenderMap[patient.gender],
          'Patient Age': patient.age,
          'Patient History Length': event.payload.studyReportTask.study.comments?.length,
          'Referring Physician': event.payload.studyReportTask.study.referring_physician,
          'No Of Images': task.images,
          'Is Bulk Upload': isBulk,
          'Scan Organs': studyReportTask.series.map((serie) =>
            seriesDescriptionProcessor(serie.description),
          ),
          'Scan Priority': studyReportTask.priority,
          'Has Attachment': studyReportTask.study.attatchments?.length !== 0,
          'Is Portable': studyReportTask.is_portable,
        }));
      } else {
        const studyReportTask = event.payload.studyReportTask as StudyReportTaskInput;
        const { patient } = studyReportTask.study;
        const response = event.payload.response as StudyReportTask;
        payload = [
          {
            'Upload Scan Source': event.payload.source,
            'Upload Input Duration': inputDuration,
            'Scan ID': response.id,
            'Patient ID': patient.patient_id,
            'Patient Gender': PatientGenderMap[patient.gender],
            'Patient Age': patient.age,
            'Patient History Length': event.payload.studyReportTask.study.comments?.length,
            'Referring Physician': event.payload.studyReportTask.study.referring_physician,
            'No Of Images': studyReportTask.study.series.length,
            'Is Bulk Upload': false,
            'Scan Organs': studyReportTask.study.series.map((serie) =>
              seriesDescriptionProcessor(serie.description),
            ),
            'Scan Priority': studyReportTask.priority,
            'Has Attachment': studyReportTask.study.attatchments?.length !== 0,
            'Is Portable': studyReportTask.is_portable,
          },
        ];
      }

      return {
        type: event.type,
        payload,
      };
    case AnalyticsEventEnum.XRAYSCAN__RADIOLOGIST_ASSIGNED:
      return {
        type: event.type,
        payload: event.payload.studyReportTasks.map<
          ArrayElement<XrayScanAssignedRadiologist['payload']>
        >((task) => ({
          'Radiologist ID': event.payload.radiologistId,
          'Scan ID': task.id,
          'Time To Assign': getDifferenceInSeconds(new Date(), new Date(task.created_at)),
        })),
      };
    case AnalyticsEventEnum.XRAYSCAN__DELETE:
      return {
        type: event.type,
        payload: {
          'Report ID': event.payload.studyReportTask.id,
          'Deleted Scan Type': event.payload.scanType,
          'Time To Delete': getDifferenceInSeconds(
            new Date(),
            new Date(event.payload.studyReportTask.created_at),
          ),
        },
      };
    case AnalyticsEventEnum.XRAYSCAN__REPORT_DOWNLOAD:
      return {
        type: event.type,
        payload: {
          'Scan ID': event.payload.studyReportTask.id,
        },
      };
    case AnalyticsEventEnum.XRAYSCAN__EDIT:
      return {
        type: event.type,
        payload: {
          'Scan ID': event.payload.studyReportTask.id,
          'Time To Edit': getDifferenceInSeconds(
            new Date(),
            new Date(event.payload.studyReportTask.created_at),
          ),
        },
      };
    case AnalyticsEventEnum.XRAYSCAN__REPORT_SAVE:
      return {
        type: event.type,
        payload: {
          'Scan ID': event.payload.studyReportTask.id,
          'Time to Save': getDifferenceInSeconds(
            new Date(),
            new Date(event.payload.studyReportTask.created_at),
          ),
        },
      };
    case AnalyticsEventEnum.XRAYSCAN__REPORT_SUBMIT:
      return {
        type: event.type,
        payload: {
          'Scan ID': event.payload.studyReportTask.id,
          'Time To Submit': getDifferenceInSeconds(
            new Date(),
            new Date(event.payload.studyReportTask.created_at),
          ),
        },
      };
    case AnalyticsEventEnum.CUSTOMER_SUPPORT_ISSUE_CREATED:
      return {
        type: event.type,
        payload: {
          'Scan ID': event.payload.studyReportTask.id,
          'Center Name': event.payload.studyReportTask.center.name,
          'Scan Priority': event.payload.studyReportTask.priority,
          'Time To Report': getDifferenceInSeconds(
            new Date(),
            new Date(event.payload.studyReportTask.created_at),
          ),
          'Issue Type': event.payload.issueType,
        },
      };
    case AnalyticsEventEnum.REPORT_SHARE:
      return {
        type: event.type,
        payload: {
          'Scan ID': event.payload.studyReportTaskId,
          'Contact Type': event.payload.contactType === 1 ? 'Patient' : 'Referring Physician',
          Phone: event.payload.phone,
          Channels: ['SMS'],
        },
      };
    default:
      return undefined;
  }
};

export { processEvent };
