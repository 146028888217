import React, { createContext, Dispatch, useMemo, useReducer } from 'react';
import {
  AuthActions,
  authDispatchMiddleware,
  AuthInfo,
  authReducer,
  AuthStatus,
  UserType,
} from './auth-services';

interface Props {
  children: React.ReactNode;
}

interface AuthContextType {
  authInfo: AuthInfo;
  dispatch: Dispatch<AuthActions>;
}

const AuthContext = createContext<AuthContextType>({} as any);

const AuthContextContainer = ({ children }: Props) => {
  const [authState, authDispatch] = useReducer<typeof authReducer>(authReducer, {
    authStatus: AuthStatus.NOT_AUTHENTICATED,
    userType: UserType.CUSTOMER_ADMIN,
    walletSummary: AuthStatus.LOGIN_SUCESS,
    userAlerts: AuthStatus.LOGIN_SUCESS, // [],
    // userConfig: AuthStatus.LOGIN_SUCESS,
  });

  const authContextValue = useMemo(
    () => ({
      authInfo: authState,
      dispatch: authDispatchMiddleware(authDispatch),
    }),
    [authState, authDispatch],
  );

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};

export { AuthContextContainer, AuthContext };
