/* eslint-disable camelcase */
import _ from 'lodash';
import { message } from 'antd';
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import {
  CursorPaginatedResponse,
  IAddPrefRadiologist,
  IBillDetailsForMonthlySection,
  IBillingRate,
  ICreateCustomer,
  ICustomerDetails,
  IRatesLinkedStudyNames,
  ICustomers,
  IMonthlyBills,
  IRadPrefType,
  IUpdateCustomerMeta,
  IVirtualAccount,
  IWalletTransactions,
  PaginatedResponse,
  StudyReportTaskDetail,
  ReportingQuality,
  StudyNameType,
  ICustomerCenter,
} from '../../common/api-types';
import { getServerAPI } from '../../common/serverAPI';
import { appConfig } from '../../common/environment';

export interface TaskFilter {
  customer_id?: string;
  age_min?: number;
  age_max?: number;
  created_at_start?: string;
  created_at_end?: string;
  center?: string | null;
  modality?: string;
  bill_payment_status?: string;
  studyNameText?:string;
  study_name_type?: string;
}

export interface TaskFilterV2 {
  customer_id?: string;
  age_min?: number;
  age_max?: number;
  created_at_start?: string;
  created_at_end?: string;
  center?: string | null;
  modality?: string;
  bill_payment_status?: string;
  study_name?:string;
  is_active?: boolean;
  reporting_quality?: Partial<ReportingQuality>;
  study_name_type?: Partial<StudyNameType>;
  should_use_for_auto_assign?: boolean;
}

export interface IAddOrUpdateBillRate {
  modality: string;
  study_name: string;
  rate1: number;
  rate2: number;
  special_view_rate: number;
  billing_type_logic: any;
  is_active: boolean;
  is_approved: boolean;
  remarks: any;
}


// edit customer rate based on id
async function updateBillingRate(id: number | any, updateData: IAddOrUpdateBillRate): Promise<string> {
  return getServerAPI()
    .url(`v2/billing/manager/bills/update_billing_rate/${id}/`)
    .put(updateData)
    .json();
}

// add new rate
async function addNewBillingRate(id: number | any, updateData: IAddOrUpdateBillRate[]): Promise<string> {
  return getServerAPI()
    .url(`v2/billing/manager/bills/create_cust_billing_rates/${id}/`)
    .post(updateData)
    .json();
}

// delete rate
async function deleteBillingRate(id: number | any): Promise<string> {
  return getServerAPI()
    .url(`v2/billing/manager/bills/delete_billing_rate/${id}/`)
    .delete()
    .json();
}



export interface ICreateBillItem {
  bill_id: number,
  description: string,
  amount: number,
}

// Create bill item - monthly section
async function createBillItemForID(data: ICreateBillItem): Promise<string> {
  return getServerAPI()
    .url('v2/billing/manager/bills/bill_item/create/')
    .post(data)
    .json();
}

export interface ICreateBillPayment {
  bill_id: number,
  payment_description: string,
  payment_amount: number,
  paid_via: string,
  payment_proof_ids: number[],
  payment_utr_number: string,
  bill_payment_remarks: string;
}


// Get bill details  - mpnthly section
async function getCustomerDetails(userID: number | undefined): Promise<ICustomerDetails> {

  const getBillDetailsForSelectedID = await getServerAPI()
    .url(`v2/imagestore/telerad/customer_details/?user_id=${userID}`)
    .get()
    .json<ICustomerDetails>();

  return getBillDetailsForSelectedID;
}


async function getCustomerWalletTransactions(
  page_size?: number,
  page_number?: number,
  customerId?: number | undefined,
  filter?: TaskFilter,
): Promise<PaginatedResponse<IWalletTransactions>> {

  const data = await getServerAPI()
    .url(`v2/billing/manager/wallet/list_transactions/?customer_id=${customerId}`)
    .query({ page_size, page_number, ..._.omitBy(filter, _.isNil) })
    .get()
    .json<PaginatedResponse<IWalletTransactions>>();

  return {
    ...data,
    results: data.response,
  };

}

// billing rates of customer
async function getBillingRates(
  page_size?: number,
  page_number?: number,
  customerId?: number | undefined,
  filter?: TaskFilter,
): Promise<PaginatedResponse<IBillingRate>> {

  const data = await getServerAPI()
    .url(`v2/billing/manager/bills/list_billing_rates/?customer_id=${customerId}`)
    .query({ page_size, page_number, ..._.omitBy(filter, _.isNil) })
    .get()
    .json<PaginatedResponse<IBillingRate>>();

  return {
    ...data,
    results: data.response,
  };

}

async function getLinkedStudyNameForCustomer(userID: number | undefined): Promise<IRatesLinkedStudyNames[]> {

  const getCustLinkedStudyNames = await getServerAPI()
    .url(`v2/billing/manager/bills/list_possible_cust_linked_study_names/${userID}/`)
    .get()
    .json<IRatesLinkedStudyNames[]>();

  return getCustLinkedStudyNames;
}


// customer apis

async function fetchCustomers(
  page_size?: number,
  page_number?: number,
  filter?: TaskFilter,
  username?: string,
  phoneno?: string,
): Promise<PaginatedResponse<ICustomers>> {
  // console.log(username);
  // console.log(phoneno);
  if(!appConfig.showCustomersListByDefault){
  if ((phoneno === undefined || phoneno?.length === 0) && (username === undefined || username?.length === 0)) {
    // message.info("Please enter Customer name or Phone number");
    return {
      count: 0,
      results: [],
      response: [],
      total_count: 0,
    };
  }
  if ((username !== undefined && username?.length < 3 && (phoneno === undefined || (phoneno !== undefined && phoneno?.length === 0)))) {
    message.error("Customer name should have a minimum of 3 characters");
    return {
      count: 0,
      results: [],
      response: [],
      total_count: 0,
    };
  }
  if ((username === undefined || username.length < 3) && phoneno !== undefined && phoneno.length === 3) {
    message.error("Phone number should have a minimum length of 5");
    return {
      count: 0,
      results: [],
      response: [],
      total_count: 0,
    };
  }
}
  const postdate = {
    "username": username,
    "phone": phoneno,
  };
  const data = await getServerAPI()
    .url(`v2/imagestore/telerad/list_customers/`)
    .query({ page_size, page_number, ..._.omitBy(filter, _.isNil) })
    .post(postdate)
    .json<PaginatedResponse<ICustomers>>();

  return {
    ...data,
    results: data.response,
  };
}

export interface IResetPassword {
  user_id: number,
  new_password: string,
}

// reset password
async function resetPasswordForCustomer(data: IResetPassword): Promise<string> {
  return getServerAPI()
    .url('v2/imagestore/telerad/change_customer_password/')
    .patch(data)
    .json();
}

// Upload csv
async function uploadCSV(id: number, data: FormData): Promise<string> {
  // console.log(data);
  const boundary = Math.random().toString().slice(2);
  const response = await getServerAPI()
    .url(`v2/billing/telerad/add_customer_billing_rates/?customer_id=${id}`)
    .headers({ 'Content-Type': `multipart/form-data;` })
    .post(data);
  const jsonResponse = await response.json();
  return jsonResponse;
}

// Get view transactions based on customer id
async function getWalletSummaryBasedOnCustomerID(customrID: number): Promise<any> {
  const data = await getServerAPI()
    .url(`v2/billing/manager/wallet/get_wallet_summary/?customer_id=${customrID}`)
    .get()
    .json<any>();
  return data;
}

// Get wallet summary for current logged in user - customer login
async function getWalletSummaryForLoggedCustomer(): Promise<any> {
  const data = await getServerAPI()
    .url(`v2/customer/wallet/get_wallet_summary/`)
    .get()
    .json<any>();
  return data;
}

/** ************************************************
 * HOOKS
 ************************************************* */

const useCustomerWalletSummary = () => {
  const data = useQuery(['customerwalletsummary'], getWalletSummaryForLoggedCustomer, {
    staleTime: 1000 * 60 * 15,
  });

  return data.data;
};


interface ICreditOrDebit {
  amount: number;
  transaction_type: string;
  description: string;
  remarks: string;
}


// credit or debit money api - wallet detail screen csr login
async function addWalletTransactions(id: number, data: ICreditOrDebit): Promise<string> {
  return getServerAPI()
    .url(`v2/telerad/wallet/add_wallet_transaction/${id}/`)
    .post(data)
    .json();
}

interface IAddMoneyRazorPay {
  amount: number;
}

// add money via razorpay
async function addMoneyViaRazorpay(id: number, data: IAddMoneyRazorPay): Promise<string> {
  return getServerAPI()
    .url(`v2/telerad/wallet/create_pg_wallet_order_request/${id}/`)
    .post(data)
    .json();
}



interface Gsheet{
  gsheet_url: string;
  customer_id: number;
}
// gsheet url
async function addCustomerRatesUsingGsheet(data: Gsheet): Promise<string> {
  return getServerAPI()
    .url('v2/billing/manager/bills/add_telereport_cust_rates_gsheet/')
    .post(data)
    .json();
}

// Create customer 
async function createCustomer(data: ICreateCustomer): Promise<string> {
  return getServerAPI()
    .url('v2/imagestore/telerad/create_customer/')
    .post(data)
    .json();
}

// update customer metdata 
async function updateCustomerMeta(data: IUpdateCustomerMeta, id: number): Promise<string> {
  return getServerAPI()
    .url(`v2/imagestore/telerad/update_customer_meta/${id}/`)
    .patch(data)
    .json();
}

// update customer metdata 
export async function updateCustomerDetails(data: ICustomerCenter, id: string): Promise<string> {
  return getServerAPI()
    .url(`v2/imagestore/telerad/update_customer_center_details/${id}/`)
    .patch(data)
    .json();
}

async function fetchVirtualAccDetailsByCustID(custID:string): Promise<IVirtualAccount> {
  return getServerAPI()
  .url(`v2/virtual_account/get_details_for_cust/${custID}`)
  .get()
  .json<IVirtualAccount>();
}


// Center info related APIS
async function changeCenterRadPref(centerID: number, data: IRadPrefType): Promise<string> {
  return getServerAPI()
    .url(`v2/imagestore/telerad/change_center_rad_preference/${centerID}/`)
    .patch(data)
    .json();
}
// Add Center pref Rads
async function addCenterPrefRadiologists(centerID: number, data: IAddPrefRadiologist): Promise<string> {
  return getServerAPI()
    .url(`v2/imagestore/telerad/add_preferred_center_radiologists/${centerID}/`)
    .patch(data)
    .json();
}

// Ignore rads adding
async function ignoreAddCenterPrefRadiologists(centerID: number, data: IAddPrefRadiologist): Promise<string> {
  return getServerAPI()
    .url(`v2/imagestore/telerad/add_ignore_center_radiologists/${centerID}/`)
    .patch(data)
    .json();
}

// Add Center pref Rads
async function removeCenterPrefRadiologists(centerID: number, data: IAddPrefRadiologist): Promise<string> {
  return getServerAPI()
    .url(`v2/imagestore/telerad/remove_preferred_center_radiologists/${centerID}/`)
    .patch(data)
    .json();
}

// Ignore rads adding
async function removeIgnoreCenterPrefRadiologists(centerID: number, data: IAddPrefRadiologist): Promise<string> {
  return getServerAPI()
    .url(`v2/imagestore/telerad/remove_ignore_center_radiologists/${centerID}/`)
    .patch(data)
    .json();
}

export {
  removeIgnoreCenterPrefRadiologists,
  removeCenterPrefRadiologists,
  ignoreAddCenterPrefRadiologists,
  addCenterPrefRadiologists,
  changeCenterRadPref,
  fetchVirtualAccDetailsByCustID,
  updateCustomerMeta,
  addMoneyViaRazorpay,
  addWalletTransactions,
  useCustomerWalletSummary,
  getWalletSummaryForLoggedCustomer,
  getWalletSummaryBasedOnCustomerID,
  uploadCSV,
  getBillingRates,
  getCustomerDetails,
  createBillItemForID,
  fetchCustomers,
  getCustomerWalletTransactions,
  resetPasswordForCustomer,
  updateBillingRate,
  addNewBillingRate,
  deleteBillingRate,
  addCustomerRatesUsingGsheet,
  createCustomer,
  getLinkedStudyNameForCustomer,
};
