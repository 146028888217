import localforage from "localforage";
import moment from "moment";

enum LocalStorageKeys {
  AUTH_TOKEN = 'AUTH_TOKEN',
  SELECTED_CENTER = 'SELECTED_CENTER',
  DARK_MODE = 'DARK_MODE',
  SCAN_ORGANS_API_DATA = "SCAN_ORGANS_API_DATA",
  SCAN_VIEWS_EMPTY_API_DATA = "SCAN_VIEWS_EMPTY_API_DATA",
  RADIOLOGISTS = "RADIOLOGISTS",
  CUSTOMER_CENTERS = "CUSTOMER_CENTERS"
}

function setLocalStorage(key: LocalStorageKeys, value: string) {
  localStorage.setItem(key, value);
}

function getLocalStorage(key: LocalStorageKeys): string | null {
  return localStorage.getItem(key);
}

function removeLocalStorage(key: LocalStorageKeys) {
  localStorage.removeItem(key);
}

export { setLocalStorage, getLocalStorage, removeLocalStorage, LocalStorageKeys };

/** ******************************
 * LOCALFORAGE IMPLEMENTATION
 ****************************** */
class LocalForageService {
  private forage: LocalForage;

  constructor(instanceName: string) {
    this.forage = localforage.createInstance({
      name: instanceName,
      driver: localforage.LOCALSTORAGE,
    });
  }

  // Method to persist data with expiration time to localforage
  public async persistData(key: LocalStorageKeys, data: any, expireInMinutes?: number | undefined): Promise<void> {
    try {
      const expiryTime = expireInMinutes ? moment().add(expireInMinutes, 'minutes').toISOString() : null;
      const dataWithMeta = {
        data,
        expiryTime,
      };
      await this.forage.setItem(key, dataWithMeta);
    } catch (error) {
      console.error("Error persisting data to localforage", error);
    }
  }

  // Method to retrieve data from localforage
  public async getPersistedData<T>(key: LocalStorageKeys): Promise<T | null> {
    try {
      const dataWithMeta = await this.forage.getItem<{ data: T; expiryTime: string | null }>(key);

      if (!dataWithMeta) return null;

      const { data, expiryTime } = dataWithMeta;

      // Check if data is expired using moment.js
      if (expiryTime && moment().isAfter(moment(expiryTime))) {
        await this.removePersistedData(key);
        return null;
      }

      return data;
    } catch (error) {
      console.error("Error retrieving data from localforage", error);
      return null;
    }
  }

  // Method to remove data from localforage
  public async removePersistedData(key: LocalStorageKeys): Promise<void> {
    try {
      await this.forage.removeItem(key);
    } catch (error) {
      console.error("Error removing data from localforage", error);
    }
  }

  // Method to clear all data from localforage
  public async clearAllData(): Promise<void> {
    try {
      await this.forage.clear();
      console.log("All data cleared from localforage");
    } catch (error) {
      console.error("Error clearing data from localforage", error);
    }
  }
}

export {LocalForageService}