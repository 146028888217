import moment from 'moment';

function getDifferenceInSeconds(date1: Date, date2: Date) {
  const diffInMs = Math.abs(date2.valueOf() - date1.valueOf());
  return diffInMs / 1000;
}

function getTimeFromNow(date: string): string {
  const givenDate = moment(date);
  return givenDate.fromNow();
}

export { getDifferenceInSeconds, getTimeFromNow };
