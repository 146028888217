/* eslint-disable no-case-declarations */
import { Dispatch } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { History } from 'history';
import { CustomerCenter, IUserAlerts, UserDetail } from '../../common/api-types';
import {
  setLocalStorage,
  LocalStorageKeys,
  removeLocalStorage,
  getLocalStorage,
} from '../../common/localstorage';
import { clearAPIAuthToken, getServerAPI, setAPIAuthToken } from '../../common/serverAPI';
import { setUser, trackEvent, resetEvent, AnalyticsEventEnum } from '../../common/metrics/metrics';
import { getWalletSummaryForLoggedCustomer } from '../customer/customer-service';
import { getUserAlerts } from '../account/account-service';
import { getUserConfigForAPP } from '../config/user-config-service';
import { AppConfig } from '../../common/config/appSettingConfig';
import { appConfig, environment, setAppConfig, setRadID } from '../../common/environment';

enum AuthStatus {
  NOT_AUTHENTICATED,
  LOGIN_IN_PROGRESS,
  LOGIN_SUCESS,
  NOT_LOGGED_IN,
}

export enum UserType {
  CUSTOMER_ADMIN,
  CUSTOMER_CENTER,
  RADIOLOGY_COMPANY_ADMIN,
  RADIOLOGY_COMPANY_MANAGER,
  RADIOLOGIST,
}

export interface AuthInfo {
  authStatus: AuthStatus;
  userInfo?: UserDetail;
  token?: {
    access: string;
    refresh: string;
  };
  authFailureMsg?: string;
  selectedCenter?: CustomerCenter;
  userType: UserType;
  walletSummary: any;
  userAlerts: IUserAlerts[] | any;
  userConfig?: AppConfig;
}

/* ******************************************
 * API CALLS
 ***************************************** */
/**
 * Auth API resonse type
 */
interface AuthResponse {
  access: string;
  refresh: string;
}

/**
 * Make API call to login and get tokens
 * @param username - Username
 * @param password - Password
 */
async function doLogin(username: string, password: string): Promise<AuthResponse> {
  let data: AuthResponse = {} as any;
  try {
    data = await getServerAPI().url('token/').post({ username, password }).json();
  } catch (err) {
    console.log(err);
    throw err;
  }
  return data;
}

/**
 * API call to get current user info
 */
export async function getUserInfo(): Promise<UserDetail> {
  return getServerAPI().url('me/').get().json<UserDetail>();
}

/**
 * Perform authentication
 * Authentication is done as below
 * - Make a call with the token to see if access token is still valid
 * - If not, try to get a new refresh token using the access token
 *
 * @param refresh - Refresh Token
 */
async function doAuth(refresh: string): Promise<UserDetail | { access: string }> {
  try {
    // We try to verify the token by making a user deatil call
    const data = await getUserInfo();
    return data;
  } catch {
    // Access token is expired, try to get a new one using refresh token
    const data = await getServerAPI()
      .url('token/refresh/')
      .post({ refresh })
      .json<{ access: string }>();

    return data;
  }
}

/**
 * API Call to update password
 * @param oldPassword .
 * @param password .
 * @param password2 .
 */
async function updatePassword(
  oldPassword: string,
  password: string,
  password2: string,
): Promise<void> {
  return getServerAPI()
    .url('me/change_password/')
    .put({ old_password: oldPassword, password, password2 })
    .json();
}

/**
 * API to update user profile details
 * @param firstName .
 * @param lastName .
 * @param email .
 */
async function updateProfile(
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
): Promise<void> {
  return getServerAPI()
    .url('me/update_profile/')
    .put({ first_name: firstName, last_name: lastName, email, phone })
    .json();
}

/* ******************************************
 * HOOKS
 ***************************************** */

enum AuthActionType {
  AUTHENTICATE,
  LOGIN,
  LOGOUT,
  SET_AUTH_IN_PRORGRESS,
  SET_AUTH_SUCCESS,
  SET_AUTH_FAILURE,
  SET_LOGGED_OUT,
  SET_USER_INFO,
  RESET_USER_INFO,
  SET_SELECTED_CENTER,
  WALLET_SUMMARY,
  USER_ALERTS,
  SET_USER_CONFIG,
}

interface AuthenticateAction {
  type: AuthActionType.AUTHENTICATE;
  payload: {
    history: History<unknown>;
  };
}

interface LoginAction {
  type: AuthActionType.LOGIN;
  payload: {
    username: string;
    password: string;
    history: History<unknown>;
  };
}

interface LogoutAction {
  type: AuthActionType.LOGOUT;
}

interface SetAuthInProgressAction {
  type: AuthActionType.SET_AUTH_IN_PRORGRESS;
}

interface SetAuthSuccessAction {
  type: AuthActionType.SET_AUTH_SUCCESS;
  payload?: {
    access: string;
    refresh: string;
  };
}

interface SetAuthFailureAction {
  type: AuthActionType.SET_AUTH_FAILURE;
  payload: {
    failureMessage?: string;
  };
}

interface SetLoggedOutAction {
  type: AuthActionType.SET_LOGGED_OUT;
}

interface SetUserInfoAction {
  type: AuthActionType.SET_USER_INFO;
  payload: UserDetail;
}

interface SetUserConfigAction {
  type: AuthActionType.SET_USER_CONFIG;
  payload: AppConfig;
}

interface ResetUserInfoAction {
  type: AuthActionType.RESET_USER_INFO;
}

interface SetSelectedCenterAction {
  type: AuthActionType.SET_SELECTED_CENTER;
  payload?: CustomerCenter;
}
interface WalletSummaryAction {
  type: AuthActionType.WALLET_SUMMARY;
  payload?: any;
}
interface UserAlertsAction {
  type: AuthActionType.USER_ALERTS;
  payload: IUserAlerts[];
}

export type AuthActions =
  | AuthenticateAction
  | LoginAction
  | LogoutAction
  | SetAuthInProgressAction
  | SetAuthSuccessAction
  | SetAuthFailureAction
  | SetLoggedOutAction
  | SetUserInfoAction
  | ResetUserInfoAction
  | SetSelectedCenterAction
  | WalletSummaryAction
  | UserAlertsAction
  | SetUserConfigAction;

const authReducer = (state: AuthInfo, action: AuthActions): AuthInfo => {
  switch (action.type) {
    case AuthActionType.WALLET_SUMMARY:
      return { ...state, walletSummary: action.payload };
    case AuthActionType.USER_ALERTS:
      return { ...state, userAlerts: action.payload };
    case AuthActionType.SET_AUTH_IN_PRORGRESS:
      return { ...state, authStatus: AuthStatus.LOGIN_IN_PROGRESS };
    case AuthActionType.SET_AUTH_SUCCESS:
      let newState = {
        ...state,
        authStatus: AuthStatus.LOGIN_SUCESS,
        authFailureMsg: undefined,
      };

      if (action.payload) {
        newState = { ...newState, token: { ...action.payload } };
      }

      return newState;
    case AuthActionType.SET_AUTH_FAILURE:
      return {
        ...state,
        authStatus: AuthStatus.NOT_LOGGED_IN,
        authFailureMsg: action.payload.failureMessage,
      };
    case AuthActionType.SET_LOGGED_OUT:
      return { ...state, authStatus: AuthStatus.NOT_LOGGED_IN };
    case AuthActionType.SET_USER_INFO:
      const userGroups = action.payload.groups.map((group) => group.name);
      let userType: UserType = UserType.CUSTOMER_ADMIN;
      if (userGroups.indexOf('Customer') !== -1) {
        userType = UserType.CUSTOMER_ADMIN;
      } else if (userGroups.indexOf('CustomerCenter') !== -1) {
        userType = UserType.CUSTOMER_CENTER;
      } else if (userGroups.indexOf('RadiologyCompany') !== -1) {
        userType = UserType.RADIOLOGY_COMPANY_ADMIN;
      } else if (userGroups.indexOf('RadiologyCompanyManager') !== -1) {
        userType = UserType.RADIOLOGY_COMPANY_MANAGER;
      } else if (userGroups.indexOf('Radiologist') !== -1) {
        userType = UserType.RADIOLOGIST;
      }
      return { ...state, userInfo: action.payload, userType };
    case AuthActionType.RESET_USER_INFO:
      return { ...state, userInfo: undefined };
    case AuthActionType.SET_SELECTED_CENTER:
      setLocalStorage(LocalStorageKeys.SELECTED_CENTER, JSON.stringify(action.payload));
      return { ...state, selectedCenter: action.payload };
    default:
      return state;
  }
};

const authDispatchMiddleware = (dispatch: Dispatch<AuthActions>): Dispatch<AuthActions> => async (
  action: AuthActions,
) => {
  switch (action.type) {
    /**
     * LOGIN
     */
    case AuthActionType.LOGIN:
      dispatch({ type: AuthActionType.SET_AUTH_IN_PRORGRESS });
      try {
        // Login and set tokens
        const data = await doLogin(action.payload.username, action.payload.password);
        setLocalStorage(LocalStorageKeys.AUTH_TOKEN, JSON.stringify(data));
        setAPIAuthToken(data.access);

        // Get User config
        const userConfig = await getUserConfigForAPP();
        setAppConfig(userConfig);
        dispatch({ type: AuthActionType.SET_USER_CONFIG, payload: userConfig });

        // Get user info
        const userInfo = await getUserInfo();
        dispatch({ type: AuthActionType.SET_USER_INFO, payload: userInfo });
        const isRadiologist = userInfo.groups.some(group => group.name === "Radiologist");
        if(isRadiologist){
          setRadID(userInfo.id);
        }
        
        if(userInfo.customer?.customer_billing_currency !== undefined){
          environment.currency = userInfo.customer?.customer_billing_currency;
          if(userInfo.customer?.customer_billing_currency === "USD"){
            appConfig.showWallet = false;
          }
        }
        /**
         * CUSTOMER ADMIN USER related
         */
        // If user has multiple centers then show all centers (For admin),
        // else select the first center as the selected center (for customers with
        // 1 center and customer admins)
        const defaultCenter = userInfo.centers?.length > 1 ? undefined : userInfo.centers?.[0];
        dispatch({ type: AuthActionType.SET_SELECTED_CENTER, payload: defaultCenter });

        // Finally set user a logged in
        dispatch({ type: AuthActionType.SET_AUTH_SUCCESS, payload: data });

        // Set the user for metrics
        setUser(userInfo, defaultCenter);
        trackEvent({
          type: AnalyticsEventEnum.USER__LOGGED_IN,
          payload: {
            type: 'Login',
          },
        });

        // Redirect to home page
        action.payload.history.replace('/');
      } catch (err) {
        dispatch({
          type: AuthActionType.SET_AUTH_FAILURE,
          payload: { failureMessage: 'Invalid Username / Password' },
        });
      }
      break;

    /**
     * LOGOUT
     */
    case AuthActionType.LOGOUT:
      removeLocalStorage(LocalStorageKeys.AUTH_TOKEN);
      removeLocalStorage(LocalStorageKeys.SELECTED_CENTER);
      clearAPIAuthToken();
      dispatch({ type: AuthActionType.RESET_USER_INFO });
      dispatch({ type: AuthActionType.SET_LOGGED_OUT });

      trackEvent({
        type: AnalyticsEventEnum.USER__LOGGED_OUT,
      });
      // Reset metrics library
      resetEvent();
      break;
    case AuthActionType.WALLET_SUMMARY:
      const walletSummaryData = await getWalletSummaryForLoggedCustomer();
      dispatch({ type: AuthActionType.WALLET_SUMMARY, payload: walletSummaryData });
      break;
    // case AuthActionType.USER_ALERTS:
    //   const userAlertsData = await getUserAlerts();
    //   // console.log(userAlertsData);
    //   dispatch({ type: AuthActionType.USER_ALERTS, payload: userAlertsData });
    //   break;
    /**
     * AUTHENTICATE
     */
    case AuthActionType.AUTHENTICATE:
      // If token not present in localstore NOT_LOGGED_IN
      const tokenStr = getLocalStorage(LocalStorageKeys.AUTH_TOKEN);
      if (tokenStr === 'undefined' || tokenStr === undefined || tokenStr === null) {
        dispatch({ type: AuthActionType.SET_LOGGED_OUT });
        action.payload.history.push('/login');
        return;
      }
      // If token present then try to authenticate
      const token = JSON.parse(tokenStr as any) as AuthResponse;
      setAPIAuthToken(token.access);

      try {
        const data = await doAuth(token.refresh);
        let userInfo;

        // If we got a new access token, save it
        if ((data as { access: string }).access !== undefined) {
          token.access = (data as any).access;
          setLocalStorage(LocalStorageKeys.AUTH_TOKEN, JSON.stringify(token));
          setAPIAuthToken(token.access);

          userInfo = await getUserInfo();
          const isRadiologist = userInfo.groups.some(group => group.name === "Radiologist");
          if(isRadiologist){
            setRadID(userInfo.id);
          }
       
        } else {
          // Else we have the user info
          userInfo = data as UserDetail;
        }
        dispatch({ type: AuthActionType.SET_USER_INFO, payload: userInfo });
        if(userInfo.customer?.customer_billing_currency !== undefined){
          environment.currency = userInfo.customer?.customer_billing_currency;
          if(userInfo.customer?.customer_billing_currency === "USD"){
            appConfig.showWallet = false;
          }
        }
        // const userAlertsData1 = await getUserAlerts();
        // dispatch({ type: AuthActionType.USER_ALERTS, payload: userAlertsData1 });

        /**
         * CUSTOMER ADMIN USER related
         */
        // Set the selected center from the localstorage
        const savedSelectedCenter: any = getLocalStorage(LocalStorageKeys.SELECTED_CENTER);
        let selectedCenter: CustomerCenter | undefined;
        if (savedSelectedCenter && savedSelectedCenter !== 'undefined') {
          selectedCenter = JSON.parse(savedSelectedCenter) as CustomerCenter;
          dispatch({ type: AuthActionType.SET_SELECTED_CENTER, payload: selectedCenter });
        } else {
          // If there is no selected center for some reason in local storage
          // If user has multiple centers then show all centers (For admin),
          // else select the first center as the selected center (for customers with
          // 1 center and customer admins)
          selectedCenter = userInfo.centers?.length > 1 ? undefined : userInfo.centers?.[0];
          dispatch({ type: AuthActionType.SET_SELECTED_CENTER, payload: selectedCenter });
        }

        // Finally SET AUTH SUCCESS and allow app to render
        dispatch({ type: AuthActionType.SET_AUTH_SUCCESS });

        // Set the user for mixpanel
        setUser(userInfo, selectedCenter);
        trackEvent({
          type: AnalyticsEventEnum.USER__LOGGED_IN,
          payload: {
            type: 'Saved',
          },
        });
      } catch {
        dispatch({ type: AuthActionType.SET_LOGGED_OUT });
        action.payload.history.push('/login');
      }
      break;
    default:
      dispatch(action);
  }
};

export {
  authReducer,
  authDispatchMiddleware,
  AuthStatus,
  AuthActionType,
  updatePassword,
  updateProfile,
};
